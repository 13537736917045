import Service from "../Service";

const resource = "FrzCtnPermissionsPersonByGroup/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
 
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID },
        });
    },

    listgrouptable(prd, requestID) {
        return Service.post(resource + "listgrouptable", prd, {
            params: { requestID: requestID },
        });
    },
}