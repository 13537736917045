<template>
    <div>
        <s-crud
            title="Permisos de Trabajadores"
            height="auto"
			:config="config"
			:filter="filter"
			add
			@save="save($event)"
			edit
			remove
			search-input
            ref="crudVist"
            >
            <template v-slot="props">
                <v-container>
                    <v-row>
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-select
                                :items="ProductionGroup"
                                item-value="PdgID"
                                item-text="PdgNameGroup"
                                label="Grupos en Produccion"
                                v-model="props.item.PdgID"
                                @input="inputPersonGroups($event)"
                                ref="txtGroupProduction"
                            >
                            </s-select>
                        </v-col>

                        <v-col cols="4" lg="4" md="6" sm="12">
                            <s-select
                                :items="PersonInGroups"
                                item-value="PgdID"
                                item-text="PgdPersonGroups"
                                label="Personas en Grupos"
                                v-model="props.item.PgdID"
                                :grupoID="props.item.PdgID"
                               
                                ref="txtPersonGroups"
                            />		
                        </v-col>

                        <v-col cols="3" lg="3" md="6" sm="12">							
							<label slot="before">Hora salida de Permiso</label>
                            <div class="relojito">
                                <datetime 
                                    title="Hora  :  Minutos" 
                                    valueZone="America/Lima"  
                                    type="time" 
                                    v-model="props.item.PpgExitHour"
                                    ref="txtHoraExit"
                                    >
                                </datetime>
                            </div>
						</v-col>  
                        <v-col cols="3" lg="3" md="6" sm="12"> 
                            <s-select-definition
                                label="Motivo de Permiso"
                                v-model="props.item.PpgMotive"
                                :def="1428"
                                ref="txtMotivoPermiso"
                            ></s-select-definition>
                        </v-col>
                        <!-- :def="1430"  produc -->
                        <!-- :def="1428"  dev -->
                    </v-row>
                </v-container>
            </template>
            <template v-slot:details="{ row }">
												
                <v-btn							
                    color="info"
                    x-small
                    fab
                    @click="openEditHourEntry(row)"
                >
                    <v-icon medium>fa-regular fa-clock</v-icon>
                </v-btn>        
            </template>

            <template v-slot:timePermissions="{ row }">
				<v-chip x-small=""  :color="row.timePermissions !='' ? 'warning': 'error'" >
					{{ row.timePermissions }}
				</v-chip>
        	</template>
        </s-crud>
        
            <v-dialog
                persistent
                v-model="showEntryHour"
                transition="dialog-bottom-transition"
                width="50%"
                v-if="showEntryHour"
            >
                <v-card class="elevation-0">
                    <s-toolbar     
                    label="Registrar Hora de Retorno"
                    dark
                    close
                    @close="closeDialog()"
                    save 
                    @save="saveEntryHour()"
                    color="primary">
                    </s-toolbar>
                    <v-container>
                        <v-row>
                            <v-col cols="3" lg="3" md="6" sm="12">							
                                <label slot="before">Hora</label>
                                <div class="relojito">
                                    <datetime 
                                        title="Hora  :  Minutos" 
                                        valueZone="America/Lima"  
                                        type="time" 
                                        v-model="itemHead.PpgEntryHour"
                                        ref="txtEntry"
                                        >
                                    </datetime>
                                </div>            
                            </v-col> 
                        </v-row>
                    </v-container>
                </v-card>
            </v-dialog>
    </div>
</template>
<script>

import _sPermissionsPersonByGroup from '@/services/FrozenProduction/FrzPermissionsPersonByGroup.js';// GRUPOS EN PRODUCCION
import _sProductionGroupService from "@/services/FrozenProduction/FrzProductionGroupService.js";// PERSONAS ASIGNADAS A LOS GRUPOS

import { Datetime } from 'vue-datetime';


export default{
    components: {Datetime},
    data(){
        return {
            itemHead: {},
            ProductionGroup:[],
            PersonInGroups:[],
            showEntryHour:false,
            config:{
                model:{
                    PpgID: "ID",
                    details: "",
                    timePermissions:''
                    // PpgExitHour: 'time',
                    // exitHour:'time',
                    // PpgEntryHour: 'time', 

                },service: _sPermissionsPersonByGroup,
                headers:[
                    {text: "Hora", value: "details", width: 20 },
                    {text:"ID", value:"PpgID"},
                    {text:"Grupo de Produccion", value:"PdgNameGroup"},
                    {text:"Trabajador", value:"DatePerson"},
                    {text:"Tipo de Permiso", value: "TypePermissions"},
                    // {text:"Hora de Salida", value:"PpgExitHour"},
                    {text:"Hora de Salida", value:"exitHour"},
                    // {text:"Hora de Entrada", value:"PpgEntryHour"},
                    {text:"Hora de Retorno", value:"entryHour"},
                    {text:"Duracion de Permiso", value:"timePermissions"},

                ]
            },
            filter:{}
        }
    }, 
    created() { 

     },
    methods:{
        save(item)
        {
            if(item.PdgID == 0  || item.PdgID == undefined)
            {
                this.$fun.alert("Seleccione un Grupo de Produccion", "warning");
                this.$refs.txtGroupProduction.focus();
                return;
            }

            if(item.PgdID == 0  || item.PgdID == undefined)
            {
                this.$fun.alert("Seleccione un Trabajador", "warning");
                this.$refs.txtPersonGroups.focus();
                return;
            }

            if(item.PpgExitHour == 0)
            {
                this.$fun.alert("Ingrese Hora de salida", "warning");
                this.$refs.txtHoraExit.focus();
                return;
            }

            if(item.PpgMotive == 0  || item.PpgMotive == undefined)
            {
                this.$fun.alert("Seleccione un Motivo", "warning");
                this.$refs.txtMotivoPermiso.focus();
                return;
            }

            console.log(item);
            item.save();
        },
        saveEntryHour(){

            this.$fun.alert("Esta seguro de guardar Hora de Retorno ?", "question").then((r) => {
            if (r.value) {
                this.itemHead.UsrUpdateID = this.$fun.getUserID(); // agrega el ID del usuario que modifico el registro
            _sPermissionsPersonByGroup
                .save( this.itemHead, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {
                        this.$fun.alert("Registrado correctamente", "success");
                        this.$emit("close");
                        this.showEntryHour = false
                        this.reload()
                    }
                });
            }
            });
        },

        inputProdGroup()
        {
            console.log ("listamos los grupos en produccion")
            _sPermissionsPersonByGroup.list(this.$fun.getUserID()).then(r =>{
                if(r.status == 200)
                {
                    this.ProductionGroup = r.data;                    
                }
            });            
        },
        inputPersonGroups(PdgID)
        {
            console.log ("listamos las personas de los grupos",PdgID)
            _sProductionGroupService.listdetail({PdgID},this.$fun.getUserID()).then(r =>{
                if(r.status == 200)
                {
                    this.PersonInGroups = r.data;           
                }
            });            
        },
        openEditHourEntry(item){
            this.showEntryHour = true
            this.itemHead = item
		},
        closeDialog(){
            this.showEntryHour = false;
        },
        reload(){
            this.$refs.crudVist.refresh();
        },


    },
    mounted(){
        this.inputProdGroup();

        // this.exitHour = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));
        this.itemHead.PpgEntryHour = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));
    }
}
</script>

<style>
.relojito {
	width:auto;
	height: auto;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(160, 159, 159);
	border-radius: 4px;
	padding: 3px;
  }
  </style>
