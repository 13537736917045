import Service from "../Service";

const resource = "FrzProductionGroup/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    listdetail(prd, requestID) {
        return Service.post(resource + "list", prd, {
            params: { requestID: requestID },
        });
    },
    assignRoll(items, requestID) {
        return Service.post(resource + "assignRoll", items, {
            params: { requestID: requestID },
        });
    },
}; 